import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router";
import Spinner from "../../components/Spinner";
import { Link } from "react-router-dom";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon, BuildingOffice2Icon, ArrowDownIcon } from "@heroicons/react/24/solid";
import { useQuery } from "@tanstack/react-query";
import apiGet, { getInitialsFromName } from "../../libs/helpers";
import Button from "../../components/Button";
import { Role } from "../../types";
import CompanyList from "./CompanyList";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Companies() {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const { isPending: companiesIsPending, data: companies } = useQuery<Company[]>({
    queryKey: ["companies", auth.sessionInfo?.idToken],
    queryFn: () => apiGet(`/strata/companies`, auth.sessionInfo?.idToken),
    initialData: [],
  });

  const filteredCompanies = searchTerm
    ? companies.filter((c) => c.company_name.toLowerCase().includes(searchTerm.toLowerCase()) || (c.internal_name && c.internal_name.toLowerCase().includes(searchTerm.toLowerCase())))
    : companies.filter((c) => c.parent_company_id === null);

  if (companiesIsPending) {
    return <Spinner />;
  } else {
    return (
      <div className="flex-grow w-full max-w-full mx-auto lg:flex">
        {/* Left sidebar & main wrapper */}
        <div className="flex-1 min-w-0 bg-white xl:flex">
          {/* Account profile */}
          <div className="xl:flex-shrink-0 xl:w-64 xl:border-r xl:border-gray-200 bg-white">
            <div className="sm:pl-6 lg:pl-8 xl:pl-0">
              <div className="flex items-center justify-between">
                <div className="flex-1 space-y-8">
                  <div className="space-y-8 sm:space-y-0 sm:flex sm:justify-between sm:items-center xl:block xl:space-y-8">
                    {/* Action buttons */}
                    <div className="flex flex-col sm:flex-row xl:flex-col">
                      <Button text="Add Company" onClick={() => navigate("/company/create")} allowedRoles={[Role.Manager]} />
                    </div>
                  </div>
                  {/* Meta info */}
                  <div className="flex flex-col space-y-6 sm:flex-row sm:space-y-0 sm:space-x-8 xl:flex-col xl:space-x-0 xl:space-y-6">
                    <div className="flex items-center space-x-2">
                      <BuildingOffice2Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="text-sm text-gray-500 font-medium">{companies.length} Companies</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white lg:min-w-0 lg:flex-1">
            <div className="sm:pl-4 pr-6 pt-3 pb-2">
              <div className="flex items-center">
                <h1 className="flex-1 text-lg font-medium">{searchTerm ? "All" : "Parent"} Companies</h1>
              </div>
            </div>
            <div>
              <input
                className="w-full rounded pl-4 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                type="text"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                placeholder="Search companies..."
              />
            </div>
            <CompanyList companies={filteredCompanies} />
          </div>
        </div>
      </div>
    );
  }
}
