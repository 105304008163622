import Button from "../../components/Button";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/authContext";

export default function ForgotPassword() {
  const [codeSent, setCodeSent] = useState(false);
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  return (
    <div className="pt-10 relative overflow-hidden flex items-center justify-center">
      {/* <SideDecorations /> */}
      <Formik
        initialValues={{
          email: "",
          code: "",
          newPassword: "",
          confirmPassword: "",
          all: "",
        }}
        validate={(values) => {
          const errors: any = {};
          if (!values.email) {
            errors.email = "Email is required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (codeSent) {
            if (!values.code) {
              errors.code = "A verification code is required";
            }
            if (!values.newPassword) {
              errors.newPassword = "A new password is required";
            }
            if (!values.confirmPassword) {
              errors.confirmPassword = "Confirmation password is required";
            } else if (values.newPassword !== values.confirmPassword) {
              errors.confirmPassword = "Passwords do not match";
            }
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          try {
            if (!codeSent) {
              await auth.sendCode(values.email);
              setCodeSent(true);
            } else {
              await auth.forgotPassword(
                values.email,
                values.code,
                values.newPassword
              );
              alert("Password was changed successfully");
              navigate("/login");
            }
          } catch (err: any) {
            setErrors({ all: err.message });
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <div className="items-center justify-center min-h-screen w-6/12 rounded-sm">
            <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg rounded-md">
              <Form>
                <h3 className="text-2xl font-bold text-center">
                  Reset your password
                </h3>
                <div>
                  <div className="mt-4">
                    <label className="block" htmlFor="email">
                      Email
                    </label>
                    <Field
                      disabled={codeSent}
                      type="text"
                      name="email"
                      className={`w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 ${codeSent && "opacity-50"}`}
                      placeholder="Email"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs"
                      name="email"
                      component="div"
                    />
                  </div>
                  {codeSent && (
                    <div>
                    <span className="text-sm">The verification code has been sent to your email</span>
                      <div className="mt-4">
                        <label className="block" htmlFor="code">
                          Code
                        </label>
                        <Field
                          type="text"
                          name="code"
                          className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                          placeholder="Code"
                        />
                        <ErrorMessage
                          className="text-red-500 text-xs"
                          name="code"
                          component="div"
                        />
                      </div>
                      <div className="mt-4">
                        <label className="block" htmlFor="newPassword">
                          New Password
                        </label>
                        <Field
                          type="password"
                          name="newPassword"
                          className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                          placeholder="New Password"
                        />
                        <ErrorMessage
                          className="text-red-500 text-xs"
                          name="newPassword"
                          component="div"
                        />
                      </div>
                      <div className="mt-4">
                        <label className="block" htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <Field
                          type="password"
                          name="confirmPassword"
                          className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                          placeholder="Confirm Password"
                        />
                        <ErrorMessage
                          className="text-red-500 text-xs"
                          name="confirmPassword"
                          component="div"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <ErrorMessage
                  className="text-red-500 text-sm mt-5"
                  name="all"
                  component="div"
                />
                <div className="flex items-baseline justify-between mt-5">
                  <Button
                    text={codeSent ? "Reset Password" : "Send Code"}
                    type="submit"
                    submitting={isSubmitting}
                  />
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}
