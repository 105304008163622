import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router";

export default function CompanySelect(props: {
    setCompanyId: (companyId: number | null) => void;
    showAllOption?: boolean;
  }) {
    const { setCompanyId, showAllOption } = props;
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const [companies, setCompanies] = useState<any>([]);

    useEffect(() => {
        async function getCompanies() {
          try {
            if (auth.sessionInfo?.idToken) {
              await fetch(
                `${process.env.REACT_APP_API_URL}/strata/companies`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: auth.sessionInfo.idToken,
                  },
                }
              )
                .then((res) => res.json())
                .then((data) => {
                  if (data.err) {
                    navigate("/companies");
                  } else {
                    setCompanies(data)
                  }
                });
            } else {
              navigate("/login");
            }
          } catch (err) {
            console.error(err);
          }
        }

        getCompanies();
    }, [])
  
    return (
      <div className="text-left">
        <label
          htmlFor="company"
          className="block text-sm font-light leading-6 text-gray-900"
        >
          Company
        </label>
        <select
          id="company"
          name="company"
          className="block w-26 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          defaultValue="select"
          onChange={(e) => e.target.value ? setCompanyId(parseInt(e.target.value)) : setCompanyId(null)}
        >
          {showAllOption && <option value="">All</option>}
          {companies.map((company: any) => (
            <option key={company.company_id} value={company.company_id}>{company.company_name}</option>
          ))}
        </select>
      </div>
    );
  }
  