import { useQuery } from "@tanstack/react-query";
import { CompanyAdminUser, LeaderScore } from "../../types";
import useApi from "../../hooks/useApi";
import { getInitialsFromName, getUserAttribute } from "../../libs/helpers";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import Dropdown from "../../components/Dropdown";

export default function Leaders() {
  const { getApiData } = useApi();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>();
  const [scoreFilter, setScoreFilter] = useState<number>();

  const { data: companyAdminUsers } = useQuery<CompanyAdminUser[]>({
    queryKey: ["companyAdminUsers"],
    queryFn: () => getApiData(`/strata/admins`),
    initialData: [],
  });

  const { data: companies } = useQuery<Company[]>({
    queryKey: ["companies"],
    queryFn: () => getApiData(`/strata/companies`),
    initialData: [],
  });

  const results = companyAdminUsers.filter((cau) =>
    !searchTerm
      ? cau.leaderScores.length > 0 && (!scoreFilter || Math.max(...cau.leaderScores.map((ls) => ls.leader_score)) === scoreFilter)
      : cau.UserStatus === "CONFIRMED" &&
        (`${getUserAttribute(cau, "given_name")?.toLowerCase()} ${getUserAttribute(cau, "family_name")?.toLowerCase()}`.includes(searchTerm.toLowerCase()) ||
          cau.companyIds.map((companyId) => companies.find((company) => company.company_id === companyId)).find((company) => company?.company_name.toLowerCase().includes(searchTerm.toLowerCase())))
  );

  // sort by leader score
  results.sort((a, b) => Math.max(...b.leaderScores.map((ls) => ls.leader_score)) - Math.max(...a.leaderScores.map((ls) => ls.leader_score)))

  return (
    <div>
      <div className="flex justify-between">
        <h1 className="text-lg text-gray-700 font-bold">{!searchTerm ? "Leaders" : "All Admins"}</h1>
        {/* <div>
          <Button text="Add Leader" icon={PlusIcon} onClick={() => navigate("/leader/add")} />
        </div> */}
      </div>
      <div className="sm:flex gap-3 mb-1 mt-2">
        <input
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          placeholder="Search all users by name or company..."
          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
        />
        <select
          className="block w-full sm:w-1/3 mt-2 sm:mt-0 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
          defaultValue="select"
          onChange={(e) => (e.target.value ? setScoreFilter(parseInt(e.target.value)) : setScoreFilter(undefined))}
        >
          <option value="">All Leader Scores</option>
          {[1, 2, 3].map((number) => (
            <option key={number} value={number}>
              Score {number}
            </option>
          ))}
        </select>
      </div>
      {results.length > 0 ? (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 mt-2">
          {results.map((user, index) => (
            <div
              key={index}
              className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-blue-700 focus-within:ring-offset-2 hover:border-gray-400"
            >
              <div className="flex-shrink-0">
                {user.leaderScores.length > 0 ? (
                  <div className="h-10 w-10 flex-none rounded-full bg-blue-700 text-white flex justify-center items-center">
                    {Math.max(...user.leaderScores.map((ls) => ls.leader_score))}
                  </div>
                ) : (
                  <div className="h-10 w-10 flex-none rounded-full bg-gray-300 text-white flex justify-center items-center">-</div>
                )}
              </div>
              <div className="min-w-0 flex-1">
                <Link to={`/leader/${user.Username}`} className="focus:outline-none">
                  <span aria-hidden="true" className="absolute inset-0" />
                  <p className="text-sm font-medium text-gray-900">{`${getUserAttribute(user, "given_name")} ${getUserAttribute(user, "family_name")}`}</p>
                  <p className="truncate text-sm text-gray-500">
                    {user.companyIds.map((companyId) => companies.find((company) => company.company_id === companyId)?.company_name).join(", ")}
                  </p>
                </Link>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-gray-400 text-sm mt-2">No Results</div>
      )}
    </div>
  );
}
