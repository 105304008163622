import { useContext, useState } from "react";
import Button from "./Button";
import AdminModal from "./AdminModal";
import { PlusIcon } from "@heroicons/react/24/solid";
import { AuthContext } from "../context/authContext";
import { CompanyAdminUser, Role } from "../types";
import { getUserAttribute } from "../libs/helpers";
import { Link } from "react-router-dom";

export default function AdminTable(props: { admins: CompanyAdminUser[]; companyId?: number }) {
  const { admins, companyId } = props;
  const [openModal, setOpenModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState<CompanyAdminUser>();
  const auth = useContext(AuthContext);

  if (admins.length > 0) {
    return (
      <div className="mt-2 flex flex-col">
        <div className="flex justify-end items-center mb-4">{auth.role === Role.Manager && <Button text="Create New Admin" onClick={() => setOpenModal(true)} />}</div>
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Given Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Family Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Created At
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {admins.map((admin, index: number) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {getUserAttribute(admin, "email")}
                        {getUserAttribute(admin, "custom:role_id") === "1" && (
                          <div className={`inline-block flex-shrink-0 ml-2 rounded-full px-1 py-0.5 text-xs text-gray-600 bg-gray-100 border`}>Strata Admin</div>
                        )}
                        {admin.leaderScores.length > 0 && (
                          <Link to={`/leader/${admin.Username}`}>
                            <div className={`inline-block flex-shrink-0 ml-2 rounded-full px-1.5 py-0.5 text-xs text-blue-600 bg-blue-100 border border-blue-600 hover:bg-blue-200`}>
                              Leader Lvl.{Math.max(...admin.leaderScores.map((ls) => ls.leader_score))}
                            </div>
                          </Link>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">{getUserAttribute(admin, "given_name")}</td>
                      <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">{getUserAttribute(admin, "family_name")}</td>
                      {admin.UserCreateDate && (
                        <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                          {new Date(admin.UserCreateDate).toDateString()} {new Date(admin.UserCreateDate).toLocaleTimeString()}
                        </td>
                      )}
                      <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                        <Button
                          text="View"
                          onClick={() => {
                            setSelectedAdmin(admin);
                            setOpenModal(true);
                          }}
                          variant="secondary"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <AdminModal open={openModal} setOpen={setOpenModal} details={selectedAdmin} companyId={companyId} />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="text-center mt-7">
        <h3 className="mt-2 text-sm font-semibold text-gray-900">No Admins</h3>
        <p className="mt-1 text-sm text-gray-500">Add an admin user with access to the customer dashboard.</p>
        <div className="mt-6">
          <Button text="New Admin" icon={PlusIcon} className="inline-flex items-center" onClick={() => setOpenModal(true)} />
        </div>
        <AdminModal open={openModal} setOpen={setOpenModal} details={selectedAdmin} companyId={companyId} />
      </div>
    );
  }
}
