export default function MonthSelect(props: {
    setYear: (month: number | null) => void;
    fromYear: number;
    toYear: number;
    showAllOption?: boolean;
  }) {
    const { setYear, fromYear, toYear, showAllOption } = props;
  
    if (fromYear > toYear) {
      return (
        <span className="text-red-500">To year must be after from year</span>
      );
    }
  
    const years = [];
    let i = fromYear;
    while(i <= toYear) {
      years.push(i);
      i++
    };
  
    return (
      <div className="text-left">
        <label
          htmlFor="year"
          className="block text-sm font-light leading-6 text-gray-900"
        >
          Year
        </label>
        <select
          id="year"
          name="year"
          className="block w-26 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          defaultValue="select"
          onChange={(e) => e.target.value ? setYear(parseInt(e.target.value)) : setYear(null)}
        >
          {showAllOption && <option value="">All</option>}
          {years.map(year => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
      </div>
    );
  }
  