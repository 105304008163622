import { ReactNode, useRef, useState } from "react";
import Tooltip from "./Tooltip";

export default function CollapsableContainer(props: { title: string; tooltip?: string; className?: string; children: ReactNode }) {
  const { title, className, children } = props;

  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={`shadow-lg border rounded-md ${className}`}>
      <button onClick={toggleCollapsible} className="flex justify-between items-center w-full p-4 text-lg font-semibold text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
        {title}
        <span>{isOpen ? "-" : "+"}</span>
      </button>

      {/* Collapsible content with animation */}
      <div
        ref={contentRef}
        style={{
          height: isOpen ? `${contentRef.current?.scrollHeight}px` : "0px",
        }}
        className={`transition-all duration-500 ease-in-out overflow-hidden`}
      >
        <div className="p-4 bg-white text-gray-700">{children}</div>
      </div>
    </div>
  );
}
