import { useQuery } from "@tanstack/react-query";
import { CompanyAdminUser, LeaderScore } from "../../types";
import useApi from "../../hooks/useApi";
import { getInitialsFromName, getUserAttribute } from "../../libs/helpers";
import { Link, useNavigate } from "react-router-dom";

export default function AddLeaders() {
  const { getApiData } = useApi();
  const navigate = useNavigate();

  const { data: companyAdminUsers } = useQuery<CompanyAdminUser[]>({
    queryKey: ["companyAdminUsers"],
    queryFn: () => getApiData(`/strata/admins`),
    initialData: [],
  });

  const { data: companies } = useQuery<Company[]>({
    queryKey: ["companies"],
    queryFn: () => getApiData(`/strata/companies`),
    initialData: [],
  });

  const confirmedUsers = companyAdminUsers.filter((cau) => cau.UserStatus === "CONFIRMED" && cau.leaderScores.length <= 0);

  return (
    <div>
      <h1 className="text-lg text-gray-700 font-bold">All Confirmed Admins</h1>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 mt-2">
        {confirmedUsers.map((admin, index) => (
          <div
            key={index}
            className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-blue-700 focus-within:ring-offset-2 hover:border-gray-400"
          >
            <div className="flex-shrink-0">
              <div className="h-10 w-10 flex-none rounded-full bg-blue-700 text-white flex justify-center items-center">
                {getInitialsFromName(`${getUserAttribute(admin, "given_name")} ${getUserAttribute(admin, "family_name")}`)}
              </div>
            </div>
            <div className="min-w-0 flex-1">
              <Link to={`/leader/${admin.Username}`} className="focus:outline-none">
                <span aria-hidden="true" className="absolute inset-0" />
                <p className="text-sm font-medium text-gray-900">{`${getUserAttribute(admin, "given_name")} ${getUserAttribute(admin, "family_name")}`}</p>
                <p className="truncate text-sm text-gray-500">
                  {admin.companyIds.map((companyId) => companies.find((company) => company.company_id === companyId)?.company_name).join(", ")}
                </p>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
