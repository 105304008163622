export default function MonthSelect(props: {
    setMonth: (month: number | null) => void;
    showAllOption?: boolean;
  }) {
    const { setMonth, showAllOption } = props;
  
    return (
      <div className="text-left">
        <label
          htmlFor="month"
          className="block text-sm font-light leading-6 text-gray-900"
        >
          Month
        </label>
        <select
          id="month"
          name="month"
          className="block w-26 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          defaultValue="select"
          onChange={(e) => e.target.value ? setMonth(parseInt(e.target.value)) : setMonth(null)}
        >
          {showAllOption && <option value="">All</option>}
          <option value="0">January</option>
          <option value="1">Febuary</option>
          <option value="2">March</option>
          <option value="3">April</option>
          <option value="4">May</option>
          <option value="5">June</option>
          <option value="6">July</option>
          <option value="7">August</option>
          <option value="8">September</option>
          <option value="9">October</option>
          <option value="10">November</option>
          <option value="11">December</option>
        </select>
      </div>
    );
  }
  