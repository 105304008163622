import { Bar } from "react-chartjs-2";
import colors from "tailwindcss/colors";

import { BarElement, ChartData } from "chart.js";
import { Chart as ChartJS } from "chart.js";
import { useState } from "react";

ChartJS.register(BarElement);

export default function GroupCategoryComparison(props: { groupCounts: any }) {
  const { groupCounts } = props;

  const colorRed = colors.red[300];
  const colorYellow = colors.yellow[300];
  const colorGreen = colors.green[400];

  const data: ChartData<"bar", number[], string> = {
    labels: groupCounts.length > 0 ? groupCounts.map((data: { name: string; }) => data.name) : [],
    datasets: [
      {
        label: "count",
        data: groupCounts.map((item: { red: number; }) => item.red), // This array is for all red values (value will follow index value of label.  Example red [0,1], groupName = [j, t] j=0 t=1)
        backgroundColor: [colorRed],
        stack: "Red" // confusing - stacks are bars
      },
      {
        label: "count",
        data: groupCounts.map((item: { yellow: number; }) => item.yellow), // This array for all yellow values
        backgroundColor: [colorYellow],
        stack: "Yellow"
      },
      {
        label: "count",
        data: groupCounts.map((item: { green: number; }) => item.green), // This array for all green values
        backgroundColor: [colorGreen],
        stack: "Green"
      },
    ],
  };

  return (
    <div className="h-[85%]">
      <Bar
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "top" as const,
              display: false,
            },
            datalabels: {display: false},
            title: {
              display: false,
              text: "Chart.js Bar Chart",
            },
            tooltip: {
              callbacks: {
                //afterBody: generateTooltip,
                // footer: generateTooltip,
              },
            },
          },
          scales: {
            y: {
              title: {
                text: "Number of Participants",
                display: true,
              },
              //suggestedMax: 5,
              ticks: {
                stepSize: 1,
              },
              suggestedMax: 5
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
        }}
        data={data}
      />
    </div>
  );
}
