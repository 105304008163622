import Button from "../../components/Button";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/authContext";
import { Link } from "react-router-dom";

export default function LogOut() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  useEffect(() => {
    auth.signOut();
  }, [])

  return <p>Logging you out...</p>;
}
