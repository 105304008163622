// import { XMarkIcon } from "@heroicons/react/24/outline";

export default function Banner() {
  return (
    <div className="flex items-center gap-x-6 bg-red-500 py-0.5 px-6 sm:px-3.5 sm:before:flex-1">
      <p className="hidden sm:block text-xs leading-6 text-white text-center w-full">Strata Admin Portal (Internal Only). Please note, certain actions may impact customers</p>
      <p className="sm:hidden text-xs leading-6 text-white text-center w-full">Strata Admin Portal (Internal Only).</p>
      <div className="flex flex-1 justify-end">
        <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
          <span className="sr-only">Dismiss</span>
          {/* <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" /> */}
        </button>
      </div>
    </div>
  );
}
