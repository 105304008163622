import React, { useState, useEffect, useContext, PropsWithChildren } from "react";

import * as cognito from "../libs/cognito";
import { Role } from "../types";

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IAuth {
  sessionInfo?: {
    username?: string;
    email?: string;
    sub?: string;
    accessToken?: string;
    refreshToken?: string;
    idToken?: string;
  };
  role: Role,
  attrInfo?: any;
  authStatus?: AuthStatus;
  claims?: any;
  signInWithEmail?: any;
  completeNewPasswordRegistration?: any;
  signUpWithEmail?: any;
  signOut?: any;
  verifyCode?: any;
  getSession?: any;
  sendCode?: any;
  forgotPassword?: any;
  changePassword?: any;
  getAttributes?: any;
  setAttribute?: any;
}

const defaultState: IAuth = {
  role: Role.Viewer,
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
};

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const AuthProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [sessionInfo, setSessionInfo] = useState({});
  const [attrInfo, setAttrInfo] = useState([]);
  const [claims, setClaims] = useState([]);
  const [role, setRole] = useState<Role>(Role.Viewer);

  useEffect(() => {
    async function getSessionInfo() {
      try {
        const session: any = await getSession();
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
          idToken: session.idToken.jwtToken
        });
        const attr: any = await getAttributes();
        const claims = attr.reduce(
          (obj: any, item: any) => ({
            ...obj,
            [item["Name"]]: item.Value,
          }),
          {}
        );
        setClaims(claims);
        setAttrInfo(attr);
        const roleId = claims["custom:role_id"] as string;
        if(roleId) {
          setRole(parseInt(roleId))
        }
        setAuthStatus(AuthStatus.SignedIn);
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut);
      }
    }
    getSessionInfo();
  }, [setAuthStatus, authStatus]);

  if (authStatus === AuthStatus.Loading) {
    return null;
  }

  async function signInWithEmail(username: string, password: string) {
    try {
      await cognito.signInWithEmail(username, password);
      setAuthStatus(AuthStatus.SignedIn);
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err;
    }
  }

  async function completeNewPasswordRegistration(
    newPassword: string,
    userAttributes: { given_name: string; family_name: string }
  ) {
    try {
        await cognito.completeNewPasswordRegistration(newPassword, userAttributes);
        setAuthStatus(AuthStatus.SignedIn);
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut);
        throw err;
      }
  }

  async function signUpWithEmail(
    username: string,
    email: string,
    password: string
  ) {
    try {
      await cognito.signUpUserWithEmail(username, email, password);
    } catch (err) {
      throw err;
    }
  }

  function signOut() {
    cognito.signOut();
    setAuthStatus(AuthStatus.SignedOut);
  }

  async function verifyCode(username: string, code: string) {
    try {
      await cognito.verifyCode(username, code);
    } catch (err) {
      throw err;
    }
  }

  async function getSession() {
    try {
      const session = await cognito.getSession();
      return session;
    } catch (err) {
      throw err;
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes();
      return attr;
    } catch (err) {
      throw err;
    }
  }

  async function setAttribute(attr: any) {
    try {
      const res = await cognito.setAttribute(attr);
      return res;
    } catch (err) {
      throw err;
    }
  }

  async function sendCode(username: string) {
    try {
      await cognito.sendCode(username);
    } catch (err) {
      throw err;
    }
  }

  async function forgotPassword(
    username: string,
    code: string,
    password: string
  ) {
    try {
      await cognito.forgotPassword(username, code, password);
    } catch (err) {
      throw err;
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      await cognito.changePassword(oldPassword, newPassword);
    } catch (err) {
      throw err;
    }
  }

  const state: IAuth = {
    role,
    authStatus,
    sessionInfo,
    attrInfo,
    claims,
    signUpWithEmail,
    signInWithEmail,
    completeNewPasswordRegistration,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
