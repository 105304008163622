import { ListBulletIcon, HomeIcon, ClockIcon, UserIcon, UserGroupIcon, ChartBarIcon, ClipboardDocumentListIcon, PaperAirplaneIcon, ArrowTrendingUpIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import { Survey } from "../../types";

export default function Stats() {
  const { getApiData } = useApi();

  const { data: mrr } = useQuery<number>({
    queryKey: ["mrr"],
    queryFn: () => getApiData(`/strata/stats/mrr`),
  });

  console.log(mrr)

  const stats = [
    { name: "Monthly Recurring Revenue (Stripe Only)", stat: mrr, icon: HomeIcon, columns: 4 },
  ];

  return (
    <div>
      <h3 className="font-semibold text-gray-800 text-xl">Strata Statistics</h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-12">
        {stats.map((item, index) => (
          <div key={index} className={`border relative overflow-hidden rounded-lg bg-white px-5 py-5 shadow col-span-${item.columns}`} style={{ gridColumn: `span ${item.columns}` }}>
            <dt>
              <div className="absolute rounded-md bg-blue-700 p-3">
                <item.icon aria-hidden="true" className="h-6 w-6 text-white" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
            </dt>
            <dd className="ml-16 flex items-baseline">
              <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
