/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { formatPhoneNumber } from "../libs/helpers";

export default function EmailSentAlert(props: {
  setShowAlert: Function;
  sentTo: Array<any>;
}) {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-800">
            Messages successfully sent
          </p>
          <div className="mt-2 text-sm text-green-700">
            <p>Messages were sent to the following recipients:</p>
            <ul className="list-disc ml-7">
              {props.sentTo.filter(user => user.email).map((user, index: number) => (
                <li key={index}>{user.email}</li>
              ))}
              {props.sentTo.filter(user => user.phone_number).map((user, index: number) => (
                <li key={index}>{formatPhoneNumber(user.phone_number?.slice(-10))}</li>
              ))}
            </ul>
          </div>
        </div>

        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
              onClick={() => props.setShowAlert(false)}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
