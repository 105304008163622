import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import Button from "./Button";

export default function PointOfContactCard(props: {pointOfContact: any, setPocEditId: Function}) {
    const {pointOfContact, setPocEditId } = props;
    return (
        <div
        key={pointOfContact.poc_id}
        className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow shadow-gray-400"
      >
        <div className="flex w-full items-center justify-between space-x-6 p-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <h3 className="truncate text-lg font-medium text-gray-900">
                {pointOfContact.poc_name}
              </h3>
              <span
                className={`inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-sm font-medium ${
                    pointOfContact.poc_type_id === 1
                    ? "text-green-800 bg-green-100"
                    : "text-gray-800 bg-gray-100"
                }`}
              >
                {pointOfContact.poc_type_name}
              </span>
            </div>
            <p className="mt-1 truncate text-gray-500 text-sm">
              {pointOfContact.poc_email}
            </p>
            <p className="mt-1 truncate text-gray-500 text-sm">
              {pointOfContact.poc_phone_number}
            </p>
            <p className="mt-1 truncate text-gray-500 text-sm">
              {pointOfContact.poc_address_1}
            </p>
            <p className="mt-1 truncate text-gray-500 text-sm">
              {pointOfContact.poc_address_2}
            </p>
            <textarea rows={4} cols={30} value={pointOfContact.notes} readOnly className="mt-1 text-sm text-gray-500 border-gray-200 w-full"></textarea>
          </div>
          <div className="flex-2">
            <Button
              text="Edit"
              variant="secondary"
              onClick={() => setPocEditId(pointOfContact.poc_id)}
            />
          </div>
        </div>
        <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            <div className="flex w-0 flex-1">
              <a
                href={`mailto:${pointOfContact.poc_email}`}
                className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
              >
                <EnvelopeIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-3">Email</span>
              </a>
            </div>
            <div className="-ml-px flex w-0 flex-1">
              <a
                href={`tel:${pointOfContact.poc_phone_number}`}
                className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
              >
                <PhoneIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-3">Call</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
}