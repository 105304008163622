import React from "react";
import colors from "tailwindcss/colors";

export default function RespondentCategory(props: { responseRate: number; green: number; yellow: number; red: number; gray: number }) {
  const { responseRate, green, yellow, red, gray } = props;

  const totalRespondents = green + yellow + red + gray;
  const data = [
    {
      value: red,
      color: colors.red[500],
      text: "Individuals experiencing extreme stress and dysfunction, significantly impairing performance, well-being, and overall functioning.",
    },
    {
      value: yellow,
      color: colors.yellow[500],
      text: "Individuals experiencing increasing levels of stress and the potential for negative impacts on performance and well-being.",
    },
    {
      value: green,
      color: colors.green[500],
      text: " Individuals with varying degrees of optimal engagement, performance, and manageable stress levels.",
    },
    {
      value: gray,
      color: colors.gray[500],
      text: "Individuals who are disengaged, not invested, or not finding meaningful work.",
    },
  ];

  return (
    <div className="">
      <div className="flex flex-col items-center md:flex-row md:justify-around">
        <div className="w-1/3 flex flex-col items-start p-4">
          <div className="bg-white border-2 border-black flex items-center justify-center h-24 w-24 mb-2">
            <h2 className="text-4xl font-bold">{totalRespondents}</h2>
          </div>
          <div className="">
            <div className="text-lg font-medium">Respondents</div>
            <div className="text-sm">{Math.round(responseRate)}% of total participants</div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          {data.map((item, index) => (
            <div key={index} className="flex items-center">
              <div
                className={`flex justify-center items-center border-2 h-16 max-w-16 bg-white`}
                style={{
                  borderColor: item.color,
                }}
              >
                <h2
                  className={`p-6 text-2xl font-bold text-${item.color}-500 mx-auto`}
                  style={{
                    color: item.color,
                  }}
                >
                  {item.value}
                </h2>
              </div>
              <div className="ml-4">
                <div className="text-sm">{item.text}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
