import { XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";

export default function WarningAlert(props: {
  title: string;
  body: string;
  dismiss: Function;
}) {
  return (
    <div className="rounded-md bg-red-50 p-4 mb-3">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{props.title}</h3>
          <div className="mt-2 text-sm text-red-700">{props.body}</div>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick={() => props.dismiss()}
              className="inline-flex rounded-md bg-red-50 p-1.5 text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
