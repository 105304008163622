import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import Button from "../../components/Button";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AuthContext } from "../../context/authContext";
import { Question, Survey } from "../../types";

export default function Example() {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [companies, setCompanies] = useState<Array<any>>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<Array<any>>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    async function getCompanies() {
      if (auth.sessionInfo?.idToken) {
        try {
          await fetch(`${process.env.REACT_APP_API_URL}/strata/companies`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: auth.sessionInfo?.idToken,
            },
          })
            .then((res) => res.json())
            .then((data) => {
              setCompanies(data);
              setFilteredCompanies(data);
            });
        } catch (err) {
          console.error(err);
        }
      }
    }

    getCompanies();
  }, []);

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Step 1</h3>
              <p className="mt-1 text-sm text-gray-600">Select the company / facility</p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Select a Company</label>
                <div className="col-span-6 sm:col-span-3">
                  <select
                    id="country"
                    name="country"
                    autoComplete="country-name"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={(e) => {
                      setSelectedCompany(
                        companies.find(
                          (company: any) => company.company_id === parseInt(e.target.value)
                        )
                      );
                    }}
                    defaultValue="select"
                  >
                    <option value="select" disabled>
                      Select an Company
                    </option>
                    {filteredCompanies.map((company: any, index: number) => (
                      <option key={index} value={company.company_id}>
                        {company.company_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      {selectedCompany && (
        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Step 2</h3>
                <p className="mt-1 text-sm text-gray-600">
                  Enter the information required to create a new assessment
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="">
                <div className="max-w-full mx-9 my-7">
                  <Formik
                    initialValues={{
                      companyId: selectedCompany.company_id,
                      name: `${selectedCompany.company_name} - ${
                        months[new Date().getMonth()]
                      } ${new Date().getFullYear()} Wellness Assessment`,
                      startDate: "",
                      endDate: "",
                      all: "",
                    }}
                    enableReinitialize
                    validate={(values) => {
                      const errors: any = {};
                      if (!values.startDate) {
                        errors.startDate = "Start date is required";
                      } else if (!values.endDate) {
                        errors.endDate = "End date is required";
                      }
                      // else if (new Date(values.startDate) < new Date()) {
                      //   errors.startDate = "Start date must be in the future";
                      // }
                      else if (values.startDate > values.endDate) {
                        errors.endDate = "End date must be after start date";
                      }
                      return errors;
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors }) => {
                      setSubmitting(true);
                      values.companyId = selectedCompany.company_id;

                      // Setting start and end dates
                      // start date will be set to 12:00am local time on the date selected
                      let splitStartDate = values.startDate.split("-").map((x) => parseInt(x));
                      let startDate = new Date(
                        splitStartDate[0],
                        splitStartDate[1] - 1,
                        splitStartDate[2],
                        0
                      )

                      // end date will be set to 11:59pm local time on the date selected
                      let splitEndDate = values.endDate.split("-").map((x) => parseInt(x));
                      let endDate = new Date(
                        splitEndDate[0],
                        splitEndDate[1] - 1,
                        splitEndDate[2],
                        23,
                        59
                      )
                      try {
                        const response = await fetch(
                          `${process.env.REACT_APP_API_URL}/strata/surveys/create`,
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: auth.sessionInfo?.idToken
                                ? auth.sessionInfo.idToken
                                : "",
                            },
                            body: JSON.stringify({
                              companyId: values.companyId,
                              name: values.name,
                              startDate,
                              endDate,
                            }),
                          }
                        );

                        if (response.status === 400) {
                          const body: ErrorResponse = await response.json();
                          console.error(body);

                          if (body.type === "DuplicateAssessmentName") {
                            setErrors({ name: body.errors.join("\n") });
                            return;
                          }
                          if (body.type === "ActiveAssessment") {
                            setErrors({ all: body.errors.join("\n") });
                            return;
                          }
                          setErrors({ all: body.errors.join("\n") });
                        }
                        if (response.status == 200) {
                          navigate("/assessments");
                        }
                      } catch (err: any) {
                        console.error(err);
                        alert("An unknown error occured");
                      }
                    }}
                  >
                    {({ isSubmitting, setFieldValue }) => (
                      <Form>
                        <div className="relative z-0 mb-6 group">
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Assessment Name
                          </label>
                          <Field
                            type="text"
                            name="name"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder=" "
                          />
                          <ErrorMessage
                            className="text-red-500 text-xs"
                            name="name"
                            component="div"
                          />
                        </div>
                        <div className="relative z-0 mb-6 w-1/2 group">
                          <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                            Presumed Start Date
                          </label>
                          <Field
                            type="date"
                            name="startDate"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder=" "
                          />
                          <ErrorMessage
                            className="text-red-500 text-xs"
                            name="startDate"
                            component="div"
                          />
                        </div>

                        <div className="relative z-0 mb-6 w-1/2 group">
                          <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                            End Date
                          </label>
                          <Field
                            type="date"
                            name="endDate"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder=" "
                          />
                          <ErrorMessage
                            className="text-red-500 text-xs"
                            name="endDate"
                            component="div"
                          />
                        </div>
                        <ErrorMessage
                          className="text-red-500 text-xs mb-3"
                          name="all"
                          component="div"
                        />

                        <Button
                          text="Create Assessment"
                          // submitting={isSubmitting}
                          type="submit"
                        />
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
