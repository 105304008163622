import { useState } from "react";
import Button from "../../components/Button";
import { PaperAirplaneIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { formatPhoneNumber, validateEmail, validatePhoneNumber } from "../../libs/helpers";
import { useQuery } from "@tanstack/react-query";
import { Survey } from "../../types";
import useApi from "../../hooks/useApi";
import { Link } from "react-router-dom";

export default function DemoAssessment() {
  const { getApiData, postApiData } = useApi();
  const [people, setPeople] = useState<{ email: string; phone: string }[]>([{ email: "", phone: "" }]);
  const [errors, setErrors] = useState<{ index: number; error: string }[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { data: surveys } = useQuery<Survey[]>({
    queryKey: ["surveys"],
    queryFn: () => getApiData(`/strata/surveys`),
    initialData: [],
  });

  const DEMO_ASSESSMENT_IDENTIFIER = "[DEMO_ASSESSMENT]";
  const demoSurvey = surveys.find((s) => s.survey_name.includes(DEMO_ASSESSMENT_IDENTIFIER));

  async function send() {
    setSubmitting(true);
    const newErrors: { index: number; error: string }[] = [];
    for (let i = 0; i < people.length; i++) {
      const personErrors: string[] = [];
      if (!people[i].email && !people[i].phone) {
        personErrors.push("Email or phone number is required");
        newErrors.push({ index: i, error: personErrors.join(", ") });
        continue;
      }
      if (people[i].email && !validateEmail(people[i].email)) {
        personErrors.push("Email is invalid");
      }
      if (people[i].phone && !validatePhoneNumber(people[i].phone)) {
        personErrors.push("Phone number is invalid");
      }
      if (personErrors.length > 0) {
        newErrors.push({ index: i, error: personErrors.join(", ") });
      }
    }
    if (newErrors.length > 0) {
      setErrors(newErrors);
      setSubmitting(false);
      return;
    }
    setErrors([]);

    if (!demoSurvey) {
      alert(`No demo assessment created, please create an assessment with the identifier ${DEMO_ASSESSMENT_IDENTIFIER}`);
      setSubmitting(false);
      return;
    }

    const { response, body } = await postApiData("/strata/surveys/send-survey-to-users", {
      surveyId: demoSurvey.survey_id,
      users: people.map((p) => ({
        email: p.email,
        phone_number: p.phone.replace(/\D/g, ""), // strip non digits
      })),
    });
    if (response.ok) {
      alert("Demo assessment(s) successfully sent");
      setPeople([{ email: "", phone: "" }]);
      setSubmitting(false);
    } else {
      console.error(response);
      console.error(body);
      alert("Error sending demo assessments");
      setSubmitting(false);
    }
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8 sm:max-w-4xl mx-auto">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Send Demo Assessment</h1>
          <p className="mt-2 text-sm text-gray-700">Choose who to send a one-time demo assessment to.</p>
          {demoSurvey && demoSurvey.survey_id ? (
            <p className="text-sm text-gray-700">
              Users will be added to{" "}
              <Link to={`/assessment/${demoSurvey.survey_id}`} className="underline text-blue-600 hover:text-blue-800">
                {demoSurvey.survey_name}
              </Link>
              .
            </p>
          ) : (
            <p className="text-sm text-red-500">
              No demo assessment found, please{" "}
              <Link to={`/assessments/create`} className="underline text-blue-600 hover:text-blue-800">
                create an assessment
              </Link>{" "}
              with the identifier {DEMO_ASSESSMENT_IDENTIFIER}.
            </p>
          )}
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button text="Add Person" variant="secondary" icon={PlusIcon} onClick={() => setPeople([...people, { email: "", phone: "" }])} />
        </div>
      </div>
      <div className="mt-4 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Email
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Phone Number
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {people.map((person, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap px-3 py-4 text-sm sm:pl-0">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        onChange={(e) => setPeople((prevState) => prevState.map((item, i) => (i === index ? { email: e.target.value, phone: item.phone } : item)))}
                        value={person.email}
                        placeholder="you@example.com"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      <input
                        id="phone"
                        name="phone"
                        type="tel"
                        onChange={(e) => setPeople((prevState) => prevState.map((item, i) => (i === index ? { email: item.email, phone: e.target.value } : item)))}
                        value={formatPhoneNumber(person.phone)}
                        placeholder="(555)-555-5555"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td>
                      <TrashIcon className="h-6 w-6 text-red-500 hover:text-red-800 cursor-pointer" onClick={() => setPeople(people.filter((_, i) => i !== index))} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {errors.length > 0 && (
          <ul className="text-red-500 text-sm mb-2">
            {errors.map((error) => (
              <li key={error.index}>
                Line {error.index + 1}: {error.error}
              </li>
            ))}
          </ul>
        )}
        <Button text="Send" icon={PaperAirplaneIcon} disabled={people.length <= 0} onClick={send} submitting={submitting} />
      </div>
    </div>
  );
}
