import Button from "../../components/Button";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/authContext";
import { useContext } from "react";

export default function Registration() {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //if(auth.)
  return (
    <div className="pt-10 relative overflow-hidden flex items-center justify-center">
      {/* <SideDecorations /> */}
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          password: "",
          confirmPassword: "",
          all: "",
        }}
        validate={(values) => {
          const errors: any = {};
          if (!values.firstName) {
            errors.firstName = "First name is required";
          }
          if (!values.lastName) {
            errors.lastName = "Last name is required";
          }
          if (!values.password) {
            errors.password = "New password is required";
          }
          if (!values.confirmPassword) {
            errors.confirmPassword = "Password confirmation is required";
          }
          if (values.password !== values.confirmPassword) {
            errors.all = "Passwords must match";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await auth.completeNewPasswordRegistration(values.password, {
              given_name: values.firstName,
              family_name: values.lastName,
            });
            navigate("/dashboard")
          } catch (err: any) {
            setErrors({ all: err.message });
          }
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <div className="items-center justify-center min-h-screen w-6/12 rounded-sm">
            <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg rounded-md">
              <Form>
                <h3 className="text-2xl font-bold text-center">
                  Welcome to Strata Intel!
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-zinc-500 text-center">
                  Please create your new account
                </p>
                <div className="mt-4">
                  <label className="block" htmlFor="firstName">
                    First Name
                  </label>
                  <Field
                    type="text"
                    name="firstName"
                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                    placeholder="First Name"
                  />
                  <ErrorMessage
                    className="text-red-500 text-xs"
                    name="firstName"
                    component="div"
                  />
                </div>
                <div className="mt-4">
                  <label className="block" htmlFor="lastName">
                    Last Name
                  </label>
                  <Field
                    type="text"
                    name="lastName"
                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                    placeholder="Last Name"
                  />
                  <ErrorMessage
                    className="text-red-500 text-xs"
                    name="lastName"
                    component="div"
                  />
                </div>
                <div className="mt-4">
                  <label className="block" htmlFor="password">
                    New Password
                  </label>
                  <Field
                    type="password"
                    name="password"
                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                    placeholder="Password"
                  />
                  <ErrorMessage
                    className="text-red-500 text-xs"
                    name="password"
                    component="div"
                  />
                </div>
                <div className="mt-4">
                  <label className="block" htmlFor="confirmPassword">
                    Confirm Password
                  </label>
                  <Field
                    type="password"
                    name="confirmPassword"
                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                    placeholder="Confirm Password"
                  />
                  <ErrorMessage
                    className="text-red-500 text-xs"
                    name="confirmPassword"
                    component="div"
                  />
                </div>
                <ErrorMessage
                  className="text-red-500 text-sm mt-5"
                  name="all"
                  component="div"
                />
                <div className="flex items-baseline justify-between mt-5">
                  <Button text="Change Password" type="submit" />
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}
// }
