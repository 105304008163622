import { XCircleIcon } from "@heroicons/react/16/solid";
import { ReactNode } from "react";

export default function Alert(props: {
  title?: string;
  message?: string | ReactNode;
  type?: "Error" | "Warning" | "Success";
  icon?: React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, "ref">>;
  className?: string;
}) {
  const { title, message, type, className } = props;

  return (
    <div className={`rounded-md ${type === "Success" ? "bg-green-50" : type === "Warning" ? "bg-yellow-50" : "bg-red-50"} p-4 ${className}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          {props.icon ? (
            <props.icon
              className={`h-5 w-5 ${type === "Success" ? "text-green-400" : type === "Warning" ? "text-yellow-400" : "text-red-400"}`}
            />
          ) : (
            <XCircleIcon
              className={`h-5 w-5 ${type === "Success" ? "text-green-400" : type === "Warning" ? "text-yellow-400" : "text-red-400"}`}
            />
          )}
        </div>
        <div className="ml-3">
          <h3
            className={`text-sm font-medium ${type === "Success" ? "text-green-800" : type === "Warning" ? "text-yellow-800" : "text-red-800"}`}
          >
            {title ?? "Error"}
          </h3>
          <div
            className={`mt-2 text-sm  ${type === "Success" ? "text-green-700" : type === "Warning" ? "text-yellow-700" : "text-red-700"}`}
          >
            {message ?? "An unexpected error occurred, please try again later"}
          </div>
        </div>
      </div>
    </div>
  );
}
