import { useContext } from "react";
import { AuthContext } from "../context/authContext";

const useApi = () => {
  const { sessionInfo } = useContext(AuthContext);

  const getApiData = async (endpoint: string) => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionInfo?.idToken ?? "",
      },
    });
    const body = await response.json();

    if (body.name === "TokenExpiredError") {
      window.location.reload();
    }

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return body
  };

  const postApiData = async (endpoint: string, requestBody: any): Promise<{response: Response, body: any | null}> => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": sessionInfo?.idToken ?? ""
      },
      body: JSON.stringify(requestBody),
    });

    let responseBody: any = null;
    try {
      responseBody = await response.json();
    } catch {
      responseBody = null;
    }

    if (responseBody && responseBody.name === "TokenExpiredError") {
      window.location.reload();
    }

    return { response, body: responseBody};
  };

  const putApiData = async (endpoint: string, requestBody: any): Promise<{response: Response, body: any | null}> => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionInfo?.idToken ?? "",
      },
      body: JSON.stringify(requestBody),
    });

    let responseBody: any = null;
    try {
      responseBody = await response.json();
    } catch {
      responseBody = null;
    }

    if (responseBody && responseBody.name === "TokenExpiredError") {
      window.location.reload();
    }

    return { response, body: responseBody};
  };

  const deleteApiData = async (endpoint: string): Promise<{response: Response, body: any | null}> => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionInfo?.idToken ?? "",
      },
    });

    let responseBody: any = null;
    try {
      responseBody = await response.json();
    } catch {
      responseBody = null;
    }

    if (responseBody && responseBody.name === "TokenExpiredError") {
      window.location.reload();
    }

    return { response, body: responseBody};
  };

  return { getApiData, postApiData, putApiData, deleteApiData };
};

export default useApi;
