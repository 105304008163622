import { ReactNode } from "react";
import Tooltip from "./Tooltip";

export default function Container(props: {
  title: string;
  tooltip?: string;
  className?: string;
  children: ReactNode;
}) {
  const { title, tooltip, className, children } = props;
  return (
    <div
      className={` bg-white shadow border rounded-md p-6 mx-auto max-w-7xl sm:px-6 lg:px-8 ${className}`}
    >
      <div className="flex items-center">
        <h3 className="text-sm font-medium text-gray-500 mr-2">{title}</h3>
        {tooltip && <Tooltip message={tooltip} position="above" />}
      </div>
      {children}
    </div>
  );
}
