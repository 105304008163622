import { useEffect, useState, useContext } from "react";
import { ChevronRightIcon, BuildingOffice2Icon } from "@heroicons/react/24/solid";
import { AuthContext } from "../../context/authContext";
import Spinner from "../../components/Spinner";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import { Activity } from "../../types";
import ActivityTable from "../../components/ActivityTable";
import ActionButtons from "./ActionButtons";
import Stats from "./Stats";

export default function Home() {
  const auth = useContext(AuthContext);
  const { getApiData } = useApi();

  const greeting = new Date().getHours() < 12 ? "Good Morning" : new Date().getHours() < 18 ? "Good Afternoon" : "Good Evening";

  const { isPending: activitiesIsPending, data: activities } = useQuery<Activity[]>({
    queryKey: ["activity"],
    queryFn: () => getApiData(`/strata/activity/latest/25`),
    initialData: [],
  });

  if (activitiesIsPending) {
    return <Spinner />;
  } else
    return (
      <>
        <div className="min-h-full">
          <div className="flex flex-col flex-1">
            <main className="flex-1">
              {/* Page header */}
              <div className="lg:mx-auto">
                <div className="md:flex md:items-center md:justify-between">
                  <div className="flex-1 min-w-0">
                    {/* Profile */}
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <h1 className="text-2xl font-bold leading-7 text-gray-800 sm:leading-9 sm:truncate">
                            {greeting}, {auth.claims?.given_name} {auth.claims?.family_name}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h2 className="mx-auto mt-5 mb-2 text-lg leading-6 font-medium text-gray-900">Actions</h2>
              <ActionButtons />

              <h2 className="mx-auto mt-10 mb-2 text-lg leading-6 font-medium text-gray-900">Statistics</h2>
              <Stats />

              <h2 className="mx-auto mt-12 text-lg leading-6 font-medium text-gray-900">Recent customer activity</h2>
              <ActivityTable activities={activities} />
            </main>
          </div>
        </div>
      </>
    );
}
