import { Link } from "react-router-dom";
import { Activity } from "../types";
import { useQuery } from "@tanstack/react-query";
import useApi from "../hooks/useApi";

export default function ActivityTable(props: { activities: Activity[] }) {
  const { activities } = props;
  const { getApiData } = useApi();

  const { data: companies } = useQuery<Company[]>({
    queryKey: ["companies"],
    queryFn: () => getApiData(`/strata/companies`),
    initialData: [],
  });

  if (activities.length <= 0) {
    return <div className="mt-4 text-gray-500 text-sm">No recent activity</div>;
  }
  return (
    <div className="mt-1">
      {/* Activity list (smallest breakpoint only) */}
      <div className="shadow sm:hidden">
        <ul role="list" className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
          {activities.map((activity) => (
            <li key={activity.activity_id}>
              <div className="block px-4 py-4 bg-white hover:bg-gray-50">
                <span className="flex items-center space-x-4">
                  <span className="flex-1 flex space-x-2 truncate">
                    <span className="flex flex-col text-gray-500 text-sm truncate">
                      <span className="truncate text-gray-800">{activity.message_template.replace("{first_name}", activity.first_name).replace("{last_name}", activity.last_name)}</span>
                      <span className="truncate">
                        <Link to={`/company/${activity.company_id}`} className="group inline-flex space-x-2 text-sm">
                          <p className="text-gray-500 group-hover:text-gray-900">{companies.find((c) => c.company_id === activity.company_id)?.company_name}</p>
                        </Link>
                      </span>
                      <time dateTime={activity.created_at}>{new Date(activity.created_at).toLocaleString()}</time>
                    </span>
                  </span>
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Activity table (small breakpoint and up) */}
      <div className="hidden sm:block">
        <div className="">
          <div className="flex flex-col mt-2">
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
                      Activity
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
                      Email
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
                      Company
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {activities.map((activity) => (
                    <tr key={activity.activity_id} className="bg-white">
                      <td className="max-w-0 w-full px-6 py-4  text-sm text-gray-900">
                        <div className="flex">
                          <div className="group inline-flex space-x-2 text-sm">
                            <p className="text-gray-500 group-hover:text-gray-900">
                              {activity.message_template.replace("{first_name}", activity.first_name).replace("{last_name}", activity.last_name)}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <div className="group inline-flex space-x-2 text-sm">
                          <p className="text-gray-500 group-hover:text-gray-900">{activity.email}</p>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <div className="flex">
                          <Link to={`/company/${activity.company_id}`} className="group inline-flex space-x-2 text-sm">
                            <p className="text-gray-500 group-hover:text-gray-900">{companies.find((c) => c.company_id === activity.company_id)?.company_name}</p>
                          </Link>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <time dateTime={activity.created_at}>{new Date(activity.created_at).toLocaleString()} </time>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
