import { useContext, useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router";
import Button from "./Button";
import UserUpdatedAlert from "./UserUpdatedAlert";
import Spinner from "./Spinner";

export default function JobMapping(props: {
  companyId: number;
  setJobTitleChanges?: Function;
}) {
  const { companyId } = props;
  const [strataJobTitles, setStrataJobTitles] = useState<Array<any>>([]);
  const [companyJobTitles, setCompanyJobTitles] = useState<Array<any>>([]);
  const [jobTitleChanges, setJobTitleChanges] = useState<any>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    async function getStrataJobTitles() {
      try {
        if (auth.sessionInfo?.idToken) {
          await fetch(`${process.env.REACT_APP_API_URL}/strata/jobTitles`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: auth.sessionInfo.idToken,
            },
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.err) {
                console.error(data.err);
              } else {
                setStrataJobTitles(data);
                setLoading(false);
              }
            });
        } else {
          navigate("/login");
        }
      } catch (err) {
        console.error(err);
      }
    }

    async function getCompanyJobTitles(companyId: number) {
      try {
        if (auth.sessionInfo?.idToken) {
          await fetch(
            `${process.env.REACT_APP_API_URL}/strata/companies/${companyId}/jobTitles`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: auth.sessionInfo.idToken,
              },
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.err) {
                console.error(data.err);
              } else {
                setCompanyJobTitles(data);
              }
            });
        } else {
          navigate("/login");
        }
      } catch (err) {
        console.error(err);
      }
    }
    getStrataJobTitles();
    getCompanyJobTitles(companyId);
  }, [auth.sessionInfo?.idToken, companyId, navigate, showAlert]);

  async function updateJobTitleMappings() {
    try {
      if (auth.sessionInfo?.idToken) {
        await fetch(`${process.env.REACT_APP_API_URL}/strata/jobTitles`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: auth.sessionInfo.idToken,
          },
          body: JSON.stringify({
            companyJobTitles: jobTitleChanges,
          }),
        })
        .then((res) => {
          if(res.status === 200) {
            window.location.reload();
          } else {
            console.error(res);
            alert("An error occurred updating job mappings")
          }
        });
      } else {
        navigate("/login");
      }
    } catch (err) {
      console.error(err);
    }
  }

  if (loading) {
    return <Spinner />;
  } else
    return (
      <div className="bg-white mx-auto rounded-lg">
        {showAlert && (
          <UserUpdatedAlert
            setShowAlert={(show: boolean) => setShowAlert(show)}
          />
        )}
        <div className="-mx-4 mb-5 mt-5 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Facility Title
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Strata Intel Title
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {companyJobTitles.map((companyTitle, index) => (
                <tr key={index}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                    {companyTitle.company_job_title_name}
                  </td>
                  <td className=" px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    <select
                      defaultValue={
                        strataJobTitles.find(
                          (sjt) =>
                            sjt.strata_job_title_id ===
                            companyTitle.strata_job_title_id
                        )?.strata_job_title_id
                      }
                      onChange={(e) => {
                        companyTitle.strata_job_title_id =
                          parseInt(e.target.value) > 0
                            ? parseInt(e.target.value)
                            : null;
                        const index = jobTitleChanges.findIndex(
                          (cjt: any) =>
                            cjt.company_job_title_id ===
                            companyTitle.company_job_title_id
                        );
                        if (index > -1) {
                          let copyOfState = [...jobTitleChanges];
                          copyOfState[index] = companyTitle;
                          setJobTitleChanges(copyOfState);
                        } else {
                          setJobTitleChanges([
                            ...jobTitleChanges,
                            companyTitle,
                          ]);
                        }
                      }}
                    >
                      <option value={0}>-- No Job Title Assigned --</option>
                      {strataJobTitles.map((strataTitle, index) => (
                        <option
                          key={index}
                          value={strataTitle.strata_job_title_id}
                        >
                          {strataTitle.strata_job_title_name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {jobTitleChanges.length > 0 && (
          <Button
            text="Update Mapping"
            onClick={() => updateJobTitleMappings()}
          />
        )}
      </div>
    );
}
