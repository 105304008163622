import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import colors from "tailwindcss/colors";
import CustomTooltip from "../Tooltip";
import RangeSelector from "../RangeSelector";
import "chartjs-adapter-date-fns";
import { subMonths, startOfMonth, isBefore, isSameMonth, format, startOfYear, min } from "date-fns";
import { enUS } from "date-fns/locale";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, ChartDataLabels);

const generateMonthsRange = (startDate: Date, endDate: Date): Date[] => {
  let months: Date[] = [];
  let currentDate = startOfMonth(startDate);
  while (isBefore(currentDate, endDate) || isSameMonth(currentDate, endDate)) {
    months.push(currentDate);
    currentDate = subMonths(currentDate, -1);
  }
  return months;
};

type TrendChartData = {
  value: number | null;
  date: Date;
};

type Dataset = {
  label: string;
  data: TrendChartData[];
  color?: string;
};

const lineColors = [colors.blue[500], colors.purple[500], colors.orange[500], colors.gray[500], colors.pink[500], colors.emerald[500], colors.amber[500], colors.sky[500], 
colors.fuchsia[500], colors.rose[500]];

export default function TrendChart(props: {
  title?: string;
  showTitle?: boolean;
  yAxisTitle?: string;
  datasets: Dataset[];
  tooltip?: string;
  hideRangeSelector?: boolean;
  hideLegend?: boolean;
  suggestedMin?: number;
  suggestedMax?: number;
  stepSize?: number;
  className?: string;
  calcType?: "average" | "add";
  showDataLabel?: boolean;
  height?: string;
}) {
  const {
    datasets,
    title,
    yAxisTitle,
    tooltip,
    hideRangeSelector,
    hideLegend,
    suggestedMin,
    suggestedMax,
    stepSize,
    className,
    calcType,
    showTitle,
    showDataLabel,
    height
  } = props;
  const [selectedDate, setSelectedDate] = useState<string>("6M");
  const [filteredData, setFilteredData] = useState<Array<{ label: string; data: { value: number | null; date: string }[]; color?: string }>>([]);

  useEffect(() => {
    const now = new Date();
    let startDate: Date;

    switch (selectedDate) {
      case "6M":
        startDate = subMonths(now, 5);
        break;
      case "1Y":
        startDate = subMonths(now, 11);
        break;
      case "YTD":
        startDate = startOfYear(now);
        break;
      case "MAX":
      default:
        // Flatten the nested data arrays and filter out nulls
        const flattenedData = datasets.flatMap((obj) => obj.data).filter((item) => item !== null);

        // Find the object with the oldest date
        const oldestObject = flattenedData.reduce((oldest, current) => {
          return new Date(oldest.date) < new Date(current.date) ? oldest : current;
        });
        startDate = oldestObject.date;
        break;
    }

    const monthsRange = generateMonthsRange(startDate, now);

    const filtered = datasets.map((dataset, index) => ({
      label: dataset.label,
      data: monthsRange.map((month) => {
        // loop over selected months
        const dataPointsInMonth = dataset.data.filter((d) => d && isSameMonth(new Date(d.date), month)); // find all data points in month
      
        if (dataPointsInMonth.length <= 0) {
          // no data points found for month, set value to null
          return {
            date: format(month, "yyyy-MM"),
            value: null,
          };
        }

        let monthlySum = 0;
        dataPointsInMonth.forEach((dp) => {
          monthlySum += dp.value ?? 0;
        });

        return {
          date: format(month, "yyyy-MM"),
          value: calcType === "add" ? monthlySum : monthlySum / dataPointsInMonth.length, // either return sum or average depending on calc type
        };
      }),
      color: dataset.color ?? lineColors[index],
    }));

    setFilteredData(filtered);
  }, [datasets, selectedDate, calcType]);

  return (
    <div className={`h-[95%] w-full static ${className ?? ""}`} style={height ? {height: height} : {}}>
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <h3 className="text-sm font-medium text-gray-500 mr-2">{showTitle ? title : ""}</h3>
          {tooltip && <CustomTooltip message={tooltip} position="above" />}
        </div>
        <div className="absolute top-4 right-4">
          {!hideRangeSelector && <RangeSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} />}
        </div>
      </div>
      <Line
        options={{
          spanGaps: true,
          responsive: true,
          plugins: {
            legend: {
              position: "top" as const,
              display: !hideLegend,
            },
            datalabels: {
              backgroundColor: function (context: any) {
                var index = context.dataIndex;
                var value = context.dataset.data[index];

                if (title === "SI Value") {
                  if (value <= 33.33) {
                    return colors.red[400];
                  } else if (value >= 66.66) {
                    return colors.green[400];
                  } else {
                    return colors.yellow[400];
                  }
                }

                return context.dataset.backgroundColor;
              },
              borderRadius: 4,
              color: "white",
              font: {
                weight: "bold",
                size: 14,
              },
              formatter: Math.round,
              padding: 6,
              align: "end",
              anchor: "end",
              display: showDataLabel ? showDataLabel : false,
            },
          },
          scales: {
            y: {
              suggestedMin,
              min: 0,
              suggestedMax,
              reverse: false,
              title: {
                text: yAxisTitle,
                display: true,
              },
              ticks: {
                stepSize: stepSize ?? 1,
              },
              grid: {
                drawBorder: true,
                color: (context) => {
                  if (context.tick.value === 0) {
                    return colors.gray[500]; // Color for the line at y = 0
                  }
                  return "rgba(0, 0, 0, 0.1)"; // Color for other grid lines
                },
              },
            },
            x: {
              type: "time",
              time: {
                unit: "month",
                round: "month",
              },
              adapters: {
                date: {
                  locale: enUS,
                },
              },
              grid: {
                display: filteredData.length > 10 ? true : false,
              },
            },
          },
          maintainAspectRatio: false,
        }}
        data={{
          labels: filteredData.length > 0 ? filteredData[0].data.map((data) => data.date) : [],
          datasets: filteredData.map((dataset) => ({
            label: dataset.label,
            data: dataset.data.map((data) => data.value),

            borderColor: dataset.color, // Use the color defined in the dataset
            borderWidth: 2,
            pointStyle: 'circle',
            pointRadius: 4,
            pointHoverRadius: 6,
            backgroundColor: dataset.color,
            fill: false,
            tension: 0.2,
          })),
        }}
      />
    </div>
  );
}
