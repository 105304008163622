import { InformationCircleIcon } from "@heroicons/react/24/outline";

export default function Tooltip(props: {
  message: string;
  position?: "above" | "below";
}) {
  const position = props.position ? props.position : "above";

  return (
    <div className="md:flex md:flex-1 md:items-center md:justify-between">
      <div className="group relative inline-block text-gray-400 underline duration-300">
        <InformationCircleIcon className="h-5 w-5" aria-hidden="true" />
        <span
          className={`${
            position === "above"
              ? "-translate-y-full after:top-[100%]"
              : "translate-y-10 after:bottom-[100%]"
          } absolute hidden group-hover:flex -left-[150px] right-3 -top-2 w-80 px-3 py-3
           rounded-lg text-center text-gray-600 text-sm after:content-[''] after:absolute after:left-1/2 
            after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-white
            z-10
            shadow-lg ring-1 ring-black/5 backdrop-filter backdrop-blur-[2px] bg-white bg-opacity-90`}
        >
          {props.message}
        </span>
      </div>
    </div>
  );
}
